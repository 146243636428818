@import "../design-system-t2/colors";
@import "../design-system-t2/fonts";
@import "../design-system-t2/dimention";

.component-button(
  @color-back-regular: @t2-color-interface-black-90-day,
  @color-text-regular: @t2-color-interface-white-100-day,
  @color-back-active: @t2-color-interface-black-70-day,
  @color-text-active: @t2-color-interface-white-08-day,
  @compact: false,
  @height: 52px
) {

  & when (@compact = true) {
    display: inline-block;
  }

  & when (@compact = false) {
    display: block;
  }

  //.font-button(/*@size: @font-size, @weight: @font-weight*/);
  .font-button(16px, @weight: 800);

  @line-height: (@height / 2);
  @safe-zone-v: ((@height - @line-height) / 2);
  @safe-zone-h: (@dim-padding * 2) ;

  align-items: center;
  text-align: center;
  line-height: @line-height;
  min-height: @height !important;
  text-decoration: none;

  border-radius: 12px;
  border: none;
  //border: transparent 1px solid;

  background-color: @color-back-regular;
  color: @color-text-regular;

  box-sizing: border-box;
  cursor: pointer;

  &:hover, &:focus {
    background-color: @color-back-active;
    color: @color-text-active;
    text-decoration: none;
  }

  // tui
  &:hover:not(._no-hover) {
    background-color: @color-back-active;
    color: @color-text-active;
  }

  padding: @safe-zone-v @safe-zone-h;
  //padding: 0 @safe-zone-h;
}

.component-button-primary(@theme: @theme,  @height: 52px) {

  & when (@theme = 'day') {
    .component-button(
            @t2-color-interface-black-90-day,
            @t2-color-interface-white-100-day,
            @t2-color-interface-black-70-day,
            @t2-color-interface-white-08-day,
            @height: @height
    );
  }

  & when (@theme = 'night') {
    .component-button(
            @t2-color-interface-black-90-night,
            @t2-color-interface-white-100-night,
            @t2-color-interface-black-70-night,
            @t2-color-interface-white-08-night,
            @height: @height
    );
  }
}

.component-button-secondary(@theme: @theme,  @height: 52px) {

  & when (@theme = 'day') {
    .component-button(
            @t2-color-interface-black-20-day,
            @t2-color-interface-black-90-day,
            @t2-color-interface-black-30-day,
            @t2-color-interface-black-80-day,
            @height: @height
    );
  }

  & when (@theme = 'night') {
    .component-button(
            @t2-color-interface-black-20-night,
            @t2-color-interface-black-90-night,
            @t2-color-interface-black-30-night,
            @t2-color-interface-black-80-night,
            @height: @height
    );
  }
}

.component-button-clear(@theme: @theme,  @height: 52px) {

  & when (@theme = 'day') {
    .component-button(
            @t2-color-interface-white-00-day,
            @t2-color-interface-black-90-day,
            @t2-color-interface-white-00-day,
            @t2-color-interface-black-80-day,
            @height: @height
    );
  }

  & when (@theme = 'night') {
    .component-button(
            @t2-color-interface-white-00-night,
            @t2-color-interface-black-90-night,
            @t2-color-interface-white-00-night,
            @t2-color-interface-black-80-night,
            @height: @height
    );
  }
}

.component-button-brand(@theme: @theme,  @height: 52px) {

  & when (@theme = 'day') {
    .component-button(
            @t2-color-interface-lime-50-day,
            @t2-color-interface-black-90-day,
            @t2-color-interface-lime-60-day,
            @t2-color-interface-black-70-day,
            @height: @height
    );
  }

  & when (@theme = 'night') {
    .component-button(
            @t2-color-interface-lime-50-night,
            @t2-color-interface-black-90-day,
            @t2-color-interface-lime-60-night,
            @t2-color-interface-black-70-day,
            @height: @height
    );
  }
}

.component-input-text() {
  background-color: @t2-color-interface-black-20-day;
  color: @t2-color-universal-text-black-50-day;
  transition: all 0.05s linear;
  //border: none;
  border: 1px solid transparent;
  border-radius: 12px;

  padding: 8px 12px;
  //min-height: 44px;
  min-height: 52px;
  box-sizing: border-box;


  &:is(:hover) {
    background-color: @t2-color-interface-background-level-4-day;
  }

  &:has(input:focus), &:has(input:active)  {
    background-color: @t2-color-interface-black-20-day;
    border: 1px solid @t2-color-interface-background-level-4-day;
  }


  --placeholder: attr(placeholder);

  &:has(input:not(:placeholder-shown)) {
    position: relative;

    input {
      @offset: 6px;
      margin-top: @offset;
      margin-bottom: (-1 * @offset);
      //--placeholder: attr(placeholder);
    }

    &:after {
    //&:focus-within:after {
      //--pl: attr(data-placeholder);
      //content: attr(data-placeholder);
      content: var(--placeholder);
      display: block;
      left: 54px;
      top: 4px;
      pointer-events: none;
      position: absolute;
      font-size: 0.8em;
      color: @t2-color-interface-black-50-day;
    }
  }

  &:focus {
    outline: none;
  }
}