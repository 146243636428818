@import "../../application/style/app";
@import "font/2024/design-system-t2-fonts";
@import "app-t2-defenition";

@desktop-offset: 52px;

/* override begin */
.image-content-branding (@image-path) {
  //content: url(@image-path);

  background-image: url(@image-path);
  //background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.press-glow() {
  @media @phone {
    &:active {
      border: none;
      box-shadow: none;
    }
  }
}

.image-content-full-branding (@image-path, @mpx: 1) {
  width: image-width(@image-path) * @mpx;
  height: image-height(@image-path) * @mpx;

  background-size: image-width(@image-path) * @mpx image-height(@image-path) * @mpx;

  .image-content-branding (@image-path);
}

.app-icon() {
  .app-icon-impl(24px, false);
}

/* override end */

// !!!!!! @import "portal-tele2-white.less";

.page.profile {
  //text-align: center;

  .page-block-main {

    & > .content {
      display: flex;
      flex: 1;

      @media @desktop {
        .t2-main-width-without-fields();
        column-gap: @dim-card-padding--desktop;
        flex-direction: row;
        .left, .right {
          .t2-card(1, "day", @mediaScope: @desktop);
        }
      }

      @media @phone {
        column-gap: @dim-card-padding--mobile;
        flex-direction: column;
        .left, .right {
          margin-top: @dim-card-margin-vertical--mobile;
          margin-bottom: @dim-card-margin-vertical--mobile;
          .t2-card(1, "day", @mediaScope: @phone, @margin: false);
        }
      }

      //.t2-card(2, "night");
      //display: inline-block;
    }
  }

  .avatar {
    width: 64px;
    height: 64px;
    border-radius: 64px;
    position: relative;
    overflow: hidden;
    .image-content-branding("./images/ico_avatar_default.png");

    img {
      width: 100%;
      height: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 18px;
      display: block;
      .image-content-branding("./images/avatarUpload.png");
      background-position: top center;
      background-size: auto;
    }
  }

  .section {
    //margin-top: 1.6em;

    &.media-body {
      margin-top: 0;
    }

    .title {
      .tele2-font-main-large();
      color: @karaoke-search-border-color;
    }

    .text {
      .tele2-font-main-large();
      font-size: 1.2em;
      line-height: 1.6em;
      color: @karaoke-t2w-color-gray;
      width: 100%;
    }

    input.text, .text.status {

      .component-input-text();

      /*-webkit-appearance: none;
      -moz-appearance: none;
      border: 0 transparent solid;
      border-bottom: 2px solid #8F9399;
      padding-bottom: 2px;

      &:focus {
        outline: none;
      }*/
    }

    .comment {
      .tele2-font-main-small();
      color: @karaoke-search-border-color;
    }
  }

  .logout {
    .tele2-font-main-regular();
    color: #D52238;
  }
}

.page {

  &.playlist,
  &.page-history
  {

    .page-block-main {
      display: block;
      max-width: unset;
      width: auto;
      box-sizing: border-box;

      .t2-text-on-card();
      .t2-card(2);
      //.t2-main-width();

      & > .content {
        //.t2-main-width();
        .t2-text-on-card();

        @media @desktop {
          .t2-main-width();
          //padding: 0 (@dim-card-padding--desktop + @desktop-offset);
          //padding: 0 (@dim-card-padding--desktop);
          padding: 0 @dim-step;

          //padding: 0 @dim-card-padding--desktop;
          /*& > * {
            margin-left: (2 * @dim-card-padding--desktop);
            margin-right: (2 * @dim-card-padding--desktop);
          }*/
          @mw: (@page-content-max-width - ((@desktop-offset + @dim-padding) * 2) );
          max-width: @mw;
          max-width: min(@mw, calc(100% - (2 * (@desktop-offset - @dim-padding))));
        }

        @media @phone {
          padding: 0 @dim-card-padding--mobile;
        }


        & > p.app-header-line {
          & > .text, & > .note {
            & when (@theme = "night") {
              //@color: "t2-color-universal-text-@{base}-90-@{theme}";
              //color: @@color;
              color: @t2-color-universal-text-white-10-day;
            }

            & when (@theme = "day") {
              //@color: "t2-color-universal-text-@{base}-10-@{theme}";
              //color: @@color;
              color: @t2-color-universal-text-black-90-day
            }
          }
        }
      }
    }
  }
}


.tele2-font-header {
  .tele2-font-header();
}

.tele2-font-main-regular {
  .tele2-font-main-regular();
}

.tele2-font-main-small {
  .tele2-font-main-small();
}

.tele2-font-main-large {
  .tele2-font-main-large();
}

.tele2-font-button {
  .tele2-font-button();
}

.nprogress(@karaoke-primary-accent-color);

body {
  color: @karaoke-t2w-color-black;
  .font-regular();
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn:active, .btn.active {
  -webkit-box-shadow: none;
  box-shadow: none;
}


#main .app-header-line {

  text-align: center;

  //background-color: transparent !important;
  //border-color: transparent !important;

  border-width: 0;

  position: relative;

  .icon {
    display: none;
    //visibility: hidden;
  }

  .text {
    display: inline-block;
    padding: 0;
    z-index: 1;

    position: relative;
  }
}


.app-header {
  margin-bottom: 0;
  .tele2-font-header();
}

.app-header-line {

  border: 0;

  &.btn {
    border-radius: 0;
    padding: 0;
    text-align: center;
  }

  .text {
    .tele2-font-header();
  }
}

.app-label {

  text-shadow: none;
  //font-weight: bold;
  .t2-text-on-card();
  background-color: transparent;

  margin: 0;
  //padding: 10px;
  padding: 10px 16px;
  .tele2-font-main-large();

  //todo
  /*&.traffic-free {
    font-size: 0.95em;
    text-align: right;
    font-style: italic;
    margin-top: -17px;
    margin-right: -15px;
    color: red;
  }*/

  &.promo {
    .t2-font-header();
    font-weight: bold;
  }
}

// ограничение ширины и фоны
.page-block {
  background-color: @karaoke-t2w-color-black;

  & > .content {
    float: unset;
    max-width: @page-content-max-width;
    margin-left: auto;
    margin-right: auto;
  }
}

page-title {
  background-color: @karaoke-t2w-color-black;
  
  .page-title {
    max-width: @page-content-max-width;
    margin-left: auto;
    margin-right: auto;
  }
}

.template-footer {
  background-color: @karaoke-t2w-color-black;
  .t2-footer {
    max-width: @page-content-max-width;
    margin: auto;

    &.mobile {
      background: @t2-color-universal-background-black-level-1-day;
      color: @t2-color-universal-text-white-10-day;
      a {
        color: @t2-color-universal-text-white-10-day;
      }
    }
  }
}

.bottom-area {
  .t2-background();

  &.page-block {
    .content > :first-child {
      margin-top: 0; // отступ управляется основным блоком
    }
  }
}

.page-block-main {
  .t2-background();

  & > .content {

    //.t2-card(3);
    //max-width: unset;

    //.card();
    //margin-top: @dim-padding;
    //max-width: (@page-content-max-width - (@desktop-offset * 2) );

    search {
      @media @desktop {
        margin-top: @dim-card-margin-vertical--desktop;
      }

      @media @phone {
        margin-top: @dim-card-margin-vertical--mobile;
      }
    }
  }
}

.page {
  .t2-background();
}




#background {
  filter: blur(10px) grayscale(100%);
  margin: -5%;
  width: 110%;
  height: 110%;

  background: @karaoke-t2w-color-white;

  &:after {
    //-webkit-filter: blur(14px);
    //background-color: rgba(223, 223, 223, 0.3);
    //background-color: rgba(0, 0, 0, 0.3);
    background-color: rgba(255, 255, 255, 0.3);
  }
}

/*.bottom-area {
  position: relative;
  &:before {
    position: absolute;
    content: '';
    display: block;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: @karaoke-t2w-color-white;
  }
}*/

table, .table {
  color: @karaoke-t2w-color-black;
  background-color: transparent;
}

// ???
.promo-triple.section-popular .selected {
  background-color: @karaoke-primary-accent-color;
}

table.promo-triple {
  color: @karaoke-t2w-color-black;

  td table td {
    background-color: @karaoke-t2w-color-lightgray;
  }
}

/*#main a.app-header-line {

  &:hover, #main &:active {
    .text,.note {
      color: #444852;
    }
  }
}*/

.colorize-arrow(@color) {
  &.app-header-line .arrow:before {
    //background-color: @color;
    background-color: #808080;
  }
}

#main &.section-main {
  .colorize-arrow(@section-main-border-color);
}
#main &.section-catalog {
  .colorize-arrow(@section-catalog-border-color);
}
#main &.section-popular {
  .colorize-arrow(@section-popular-border-color);
}
#main &.section-playlist {
  .colorize-arrow(@section-playlist-border-color);
}
#main &.section-new {
  .colorize-arrow(@section-new-border-color);
}
#main &.section-feedback {
  .colorize-arrow(@section-playlist-border-color);
}


.page.profile .section .app-header-line .text {
  .font-page-title();
}



.modal-content {
  background-color: @karaoke-t2w-color-white;
  border-radius: 0;
}

.modal {

  .btn {
    .tele2-font-button();
    border-radius: @app-dim-button-border-radius;
    //padding: 19px 45px;
    color: #1F2229;
    background-color: transparent;

    border: 2px solid #1F2229;
    /* min-width: 100px; */
    padding: 8px 20px;
    //text-transform: uppercase;

    &:focus, &:hover {
      color: @karaoke-t2w-color-lightgray;
      background-color: @karaoke-primary-accent-color;
      border-color: @karaoke-primary-accent-color;
    }
  }

  .btn.btn-success {
    .component-button-primary();
  }

  .btn.btn-subscribe-main, .btn.btn-subscribe-plus {
    .component-button-brand();
  }

  .btn.btn-primary {
    background-color: transparent;
    border-color: @karaoke-t2w-color-white;

    &:focus, &:hover {
      color: @karaoke-t2w-color-lightgray;
      background-color: @karaoke-t2w-color-white;
      border-color: @karaoke-t2w-color-lightgray;
    }
  }
}

/*#main .catalog-item, .cover-wrapper, .song-batch-carousel {

  td {
    filter: grayscale(100%) brightness(125%);

    &:hover, &:active {
      filter: none;
    }
  }
}*/

.cover {
  width: 124px;
  height: 124px;
}

.song-batch-carousel {
  background: @t2-color-universal-background-white-level-2-day;
  //margin: 0 -15px 0px;
  //margin: 0px -22px;
  margin: 0 (-1 * @dim-layout-margin--mobile);
  width: auto;

  //background-image: url('./images/bg_promo.svg');
  //background-size: cover;

  /*  .cover {
      width: 130px;
      height: 130px;
    }*/

  .rn-carousel-controls .rn-carousel-control.rn-carousel-control-next:before {
    //padding: 0;
    //margin: 40px -5px;
  }
}

// fix - нужна не везде
.cover:hover {
  box-shadow: none;
}
.cover {
  border-radius: @dim-card-image-radius;
  overflow: hidden;
  .description {
    transition: background-color 0.05s ease-in-out;
  }
}
.catalog-item, .song-batch-carousel {
  /*& .cover:hover {
    //box-shadow: @karaoke-primary-accent-color -5px 0px 0em;
    .description {
      background-color: hsla(0, 0%, 100%, .95);
    }
  }*/

  & .cover {
    .description {
      background-color: @t2-color-universal-background-white-level-1-day;
      }
  }
}

.catalog-item {

  .album {
    /*font-family: 'Tele2DisplaySerifWebSHORT-Bold', Helvetica, Arial, serif;
    font-style: normal;
    font-style: normal;
    font-weight: bold;*/
    .tele2-font-main-large();

    color: @karaoke-t2w-color-black;
    background: transparent;
    text-align: left;
    text-decoration: none;

    /*&:hover {
      box-shadow: @karaoke-primary-accent-color -5px 0 0;
    }*/

    .component-button(@compact: true)
    
  }

}



.promo-triple {
  .author {
    .tele2-font-main-small();
    line-height: 1.1em;
  }
  .title {
    .tele2-font-main-regular();
    line-height: 1.1em;
  }
}

.song-item, .song-item.btn {

  border-radius: 8px;
  color: @karaoke-t2w-color-black;
  background-color: @t2-color-universal-background-white-level-2-day;
  //border-bottom: 1px solid #f5f5fa;
  border-color: transparent;
  padding: 0;

  /*&:hover {
    background-color: @t2-color-universal-background-white-level-3-day;
  }*/

  .author {
    .tele2-font-main-small();
    color: @karaoke-t2w-color-gray;
  }
  .name {
    .tele2-font-main-large();
    color: @karaoke-t2w-color-black;
  }

  .playlist-action, .play{
    .app-icon-impl(32px);
  }

  /*.content {
    padding: 7px 0 7px;
  }*/
}

.loader-page {
  color: @karaoke-t2w-color-black;
  a:hover, a:focus, .btn:hover, .btn:focus, .btn.focus {
    color: @karaoke-primary-accent-color;
  }
}

#loader {
  //background: #1a1a1a;
  //background: #e5e5e5;
  background: #fcfcfc;
  //

  .loader-page, .loader-app {
    color: @karaoke-t2w-color-black;
  }

  .loader {
    //filter: invert(100%) brightness(110%);
    &:before {
      background-image: url("./images/loader_m.gif");
      background-size: image-width("./images/loader_m.gif") image-height("./images/loader_m.gif");
    }
  }
}



#main .app-header-line.even {
  position: relative;
  text-transform: none;

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    background-color: @t2-color-universal-background-white-level-2-day !important;
  }
}

.jumbotron {
  background-color: transparent;
  border: none;

  .t2-text-on-back();
}

.page.search  {
  .jumbotron {
    .t2-text-on-card();
  }
}

.feedback-block {
  .tele2-font-main-small();
}

#feedback {
  .component-input-text();
  box-shadow: none;
}

#main .playlist-panel {

  /*&.affix-top {
    margin: 10px -15px 0px;
  }*/

  &.affix {
    background-color: @karaoke-t2w-color-white;
    //box-shadow: none;
    left: 0;
    right: 0;
    padding-left: 2px;
    padding-right: 2px;

    .app-header-line {
      padding: 5px 0;
      margin: 0;

      .text {
        .t2-text-on-card(@clickable: true, @base: "black", "day");
      }

      @media @phone {
        .text {
          line-height: 32px !important;
        }
      }

      .arrow {
        margin-top: 0;
      }

      &:hover {
        .note {
          color: @karaoke-primary-accent-color;
        }
      }
    }
  }

  &.affix-top {
    .app-header-line {
      padding: 0 0 @dim-card-padding--desktop;

      .arrow {
        margin-top: -4px;
      }

      .note {
        //margin-top: 2px;
        margin-top: -7px;
      }

      &:hover {
        .note {
          color: @karaoke-primary-accent-color;
        }
      }
    }

    .text {
      .t2-text-on-card();
      .font-page-title();
      font-size: 26px;

      padding-left: 0;
    }
  }

  /*.app-header-line {
    text-align: left;
  }*/

  .text {
    //.t2-font-regular();
    .font-page-title();
    
    font-style: normal;
    font-size: 16px;
    line-height: 36px;
    font-weight: normal;
    margin-left: 15px;
    margin-bottom: 0;
  }

  @media @desktop {
    .text {
      .font-page-title();
      padding: 12px 34px;
    }

    &.affix .app-header-line {
      .text {
        //margin-top: 15px;
        margin-right: @dim-layout-margin--mobile;
      }

      .note {
        //right: 32px;
        //margin-top: 11px;
        margin-top: 6px;
      }

      .arrow {
        margin-top: 6px;
      }
    }
  }

}

.section-playlist {

  .subtext {
    display: block;
    font-size: 0.8em;

    margin-top: -6px; // margin-bottom: 6px
    margin-bottom: 6px;
  }

  .app-icon {
    position: absolute;
    right: 10px;
    top: 5px;
    border: none;

    &.clear {
      //.image-content-branding("./images/ico_close.png");
    }

    &.filter {
      .image-content-branding("./images/ico_filter.png");
    }
  }
}



.app-header-line .note, .app-header-line .text {
  font-variant: common-ligatures;
}


.song-item {
  .play {
    background-color: @t2-color-interface-background-level-3-day;
    border-radius: 50%;
    background-image: url('./images/ico_play.svg');
    margin-top: 4px;
  }

  .playlist-added {
    background-image: url('./images/ico_check.png');
    margin-top: 2px;
  }

  .playlist-add {
    background-image: url('./images/ico_plus.png');
    margin-top: 2px;
  }
}


.button-send {
  .component-button-primary();
}

.player-finish {
  .btn-primary.btn {
    .component-button-primary();

    width: auto;
    display: inline-block;
  }

  .btn-secondary.btn {
    .component-button-secondary();

    width: auto;
    display: inline-block;
  }
}

.art-play-finish {
  background-image: url('./images/art_play_finish.png');
}

.art-play-finish {
  position: relative;

  & > .score {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    background-color: @karaoke-primary-accent-color;
    mix-blend-mode: overlay;
    position: absolute;
    bottom: 0;
    border-radius: 80%;
    transition: height 3000ms linear;

    &.score-0, &.score-1 { height: 25%; }
    &.score-2 { height: 35%; }
    &.score-3 { height: 45%; }
    &.score-4 { height: 65%; }
    &.score-5 { height: 70%; }
    &.score-6 { height: 75%; }
    &.score-7 { height: 80%; }
    &.score-8 { height: 85%; }
    &.score-9, &.score-10 { height: 100%; }
  }
}

.feedback-block .app-icon {
  background-image: url('./images/ico_feedback.png');
}

.bootbox.modal {

  .modal-content {
    .t2-card(@theme: "day", @margin: true, @padding: false);
    border: 0;
    padding: 12px 24px;
    border-radius: 20px !important;
    .tele2-font-main-large();
  }

  .modal-header {
    padding: 12px 0;
  }

  .modal-body {
    padding: 12px 0;
  }

  .modal-footer {
    border-top: 0;
    text-align: left;
    padding: 20px 0 12px;
  }

  .btn + .btn.btn-close {
    display: none;
  }

  button.close {
    @size: 24px;
    .image-content-branding("./images/ico_close.svg");
    color: transparent;
    width: @size;
    height: @size;
    background-size: @size @size; // как в меню
    border-radius: 21px;
    padding: 5px;

    opacity: 1;
    text-shadow: none;
    margin-left: 10px;
    margin-bottom: 2px;

    //top: -5px;
    //right: -12px;
    /*top: 12px;*/
    /* right: -8px; */
    position: relative;
  }

  .bootbox-body {
    .tele2-font-main-regular();
    line-height: 22px;
  }

  .btn, .btn + .btn {
    .component-button-secondary();
    display: inline-block;
  }

  .btn-success {
    .component-button-primary();
    display: inline-block;
  }

  /*.btn + .btn {
    display: inline-block;
    margin: 0 auto 20px
  }*/
}

.section-playlist .note {
  .tele2-font-main-regular();
  background: #f5f5fa;
  border-radius: @app-dim-button-border-radius;
  padding: 2px 12px;
  //margin: 6px 10px;
  margin: -4px 10px;

  font-size: @t2-font-dim-body-desktop-size;
  min-height: (@app-dim-button-border-radius * 3);
  line-height: (@app-dim-button-border-radius * 3);
}

.playlist-panel.affix {
  box-shadow: 0 1px 8px #828282;
}

.modal-subscribe-song.bootbox.modal {
  .modal-dialog {
    .modal-content {
      .modal-footer {
        .btn.btn-success, .btn.btn-subscribe-main, .btn.btn-subscribe-plus {
          .component-button-primary();
          display: inline-block;
        }
      }
    }
  }
}

.song-batch-carousel {
  .rn-carousel-controls {
    .rn-carousel-control.rn-carousel-control-prev{
      opacity: 1;
      width: 44px;
      &:before {
        content: url('./images/ico_arrow_carousel_left.png');
      }
    }

    .rn-carousel-control.rn-carousel-control-next {
      opacity: 1;
      width: 44px;
      &:before {
        content: url('./images/ico_arrow_carousel_right.png');
      }
    }
  }
}

.app-header-line {

  .t2-text-on-card();
  
  .arrow, .note {
    position: absolute;
  }

  .note {
    right: 24px;
    .t2-text-on-card(@theme: 'day');
  }

  .arrow {
    right: 6px;
    margin-top: 2px;

    &:before {
      //background-image: url('./images/ico_arrow_right.svg');
      //-webkit-filter: invert(40%) grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(400%) contrast(2);
      //filter: grayscale(100%) brightness(40%) sepia(100%) hue-rotate(-50deg) saturate(600%) contrast(0.8);
      //box-shadow:inset 0 0 0 2000px rgba(255,0,150,0.3);

      background-color: black;
      background-image: none;

      -webkit-mask-image: url('./images/ico_arrow_right.svg');
      //-webkit-mask-size: 25px;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: center;

      mask-image: url('./images/ico_arrow_right.svg');
      //mask-size: 25px;
      mask-repeat: no-repeat;
      mask-position: center;
    }
  }
}


@media all and (max-width: 440px) {
  .app-header-line .note-search {
    display: none;
  }
}

.form-control {
  border-radius: 0;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  padding-left: @dim-layout-margin--mobile;
  padding-right: @dim-layout-margin--mobile;
}

.content {
  padding-left: @dim-layout-margin--mobile;
  padding-right: @dim-layout-margin--mobile;
}

@media only screen and (max-width: 480px) and (min-width: 320px) {
  .catalog-item .cover {
    width: 124px;
    height: 124px;
  }
}

h6.jumbotron, .jumbotron h6 {
  .tele2-font-main-regular();
}

label {
  font-weight: normal;
}

.app-label.search-label {
  background: transparent;
  .tele2-font-main-large();
}




@media only screen and (max-width: 480px) and (min-width: 320px) {
  .catalog-item .album {
    min-height: 54px;
  }
}

a {
  //color: @karaoke-primary-accent-color;
  color: @karaoke-t2w-color-black;
  //text-decoration: underline;
  &:hover, &:focus {
    color: @karaoke-primary-accent-color;
  }
}

.modal-header {
  border-bottom: none;

  .modal-title {
    color: @karaoke-t2w-color-black;
    .tele2-font-header();
    text-transform: uppercase;
  }
}

//~~~~~~~~~ Мои исполнения
song-item {
  &.event-play .play {
    background-image: url('./images/ico_play.png');
  }
  &.event-perf .play {
    background-image: url('./images/ico_record.png');
  }
  &.event-perf .playlist-action {
    background-image: url('./images/ico_close.png');
  }
}

button-row.history-mode {

  .icon {
    width: 42px;
    height: 42px;
    display: block;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    background-size: 100%;
  }

  .name {
    // todo: flexbox
    margin-top: 11px;
    margin-bottom: 14px;
  }

  & .state-all .icon {
    background-color: @t2-color-interface-background-level-2-day;
    border-radius: 50%;
    background-image: url("./images/ico_play.svg");
  }

  & .state-rec .icon {
    background-image: url("./images/ico_record.png");
  }
}

// mobile
@media @phone {
}

// patch - выравниваение заголовков по левому краю
#main {
  @media @desktop {
    .app-header-line {
    text-align: left;
    //padding: 5px 14px;
  }
  }
  .affix-top .app-header-line {
    margin-left: -15px;
  }
}

// desktop
@media @desktop {
  .song-batch-carousel {
    display: none;
  }
}

#main .app-header-line {
  margin: 10px -15px 10px 0;
  //padding: 0;
}

@import "element-catalog";
@import "element-cover";

.cover {
  .description {
    color: @karaoke-t2w-color-black;
    background-color: @t2-color-universal-background-white-level-1-day;
  }
}


//@import "../../external/bootstrap/less/variables";
@import "../../application/style/bootstrap/variables";
@brand-primary: @karaoke-primary-accent-color;
@brand-info: @karaoke-primary-accent-color;

@pagination-color: @karaoke-t2w-color-gray;
@pagination-bg: @karaoke-t2w-color-white;
@pagination-border: @t2-color-universal-background-white-level-2-day;

@pagination-active-color: @karaoke-t2w-color-white;
@pagination-active-bg: @karaoke-search-border-color; /*@brand-primary;*/
@pagination-active-border: @karaoke-search-border-color; /*@brand-primary;*/

@pagination-disabled-color: @t2-color-universal-background-white-level-3-day;
@pagination-disabled-bg: @t2-color-universal-background-white-level-2-day;
@pagination-disabled-border: @t2-color-universal-background-white-level-2-day;

@border-radius-base: 0;
@border-radius-large: @border-radius-base;
@border-radius-small: @border-radius-base;

@import "../../external/bootstrap/less/mixins";
@import "../../external/bootstrap/less/pagination";


.pagination > li > a,
.pagination > li > span {
  .t2-text-on-card();
  border: none;
  border-radius: 8px;
  min-width: 36px;
  display: inline-block;
  margin: 0 4px;
}

.pagination > li.active {
  & > a, & > span {
    background-color: @t2-color-gradient-electric-blue-50-day;
  }
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  //display: none;
  background-color: transparent;
  opacity: 0;
  cursor: unset;
}

.pagination > li:first-child > a {
  color: transparent;
  background-image: url("./images/paginaton-prev.svg");
  background-size: 8px 14px;
  background-repeat: no-repeat;
  background-position: center center;
  &:hover {
    color: transparent;
  }
}
.pagination > li:last-child > a {
  color: transparent;
  background-image: url("./images/paginaton-next.svg");
  background-size: 8px 14px;
  background-repeat: no-repeat;
  background-position: center center;
  &:hover {
    color: transparent;
  }
}


.page-history .section-playlist.secondary {
  display: none;
}
@media @desktop {
  .page-history {
    .section-playlist.app-header-line {
      .subtext, .filter {
        display: none;
      }
    }

    .section-playlist.secondary {
      display: block;
      margin-bottom: 18px;

      & > * {
        display: inline-block;
        width: 300px;
      }

      .label {
        position: relative;
        color: #828282;
        padding-left: 58px;
        text-align: left;
        font-weight: normal;
        font-size: 1em;

        .app-icon {
          left: 12px;
          top: -5px;
          right: auto;
          opacity: 0.6;
          float: left;
        }
      }

      button-row {
        display: inline-block;
        width: 300px;
        .name {
          white-space: nowrap;
        }

        &.active .song-item {
          background-color: @karaoke-primary-accent-color;
        }
      }
    }

    .grid-wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr;
      grid-gap: 0 2vw;
    }
  }
}

@media @desktop {
  .page {
    padding-bottom: 25px;
    @background: "t2-color-universal-background-@{base}-level-1-@{theme}";
    background: @@background;
  }

  .bootbox.modal {
    .modal-dialog {
      margin: 0 auto;
    }
    .modal-footer {
      text-align: left;
      .btn, .btn + .btn {
        display: inline-block;
        margin: 0 16px 0 0;
        //min-width: 224px;
      }
    }
  }
}

.siri-container {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  canvas {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
  }
}






.player-finish {
  .upload {
    color: #6f6f6f;
  }

  .t2-main-width();
  .t2-card(2/*, @margin: false*/);
  width: auto;
  display: block;

  .block-first {
    .score, .upload {
      .t2-text-on-card();
    }
  }
  .block-second {
    //.t2-card();
    background-color: transparent;
  }
  .art-play-finish {
    background-color: white;
  }

  .btn-primary, .btn-primary.btn {
    .component-button-primary();
    display: inline-block;
  }
}

.app-header-line.btn.section-new {
  .t2-text-on-back();
}


// cтилизация отдельных страниц будет здесь
.page {
  &.profile > .page-block-main {
    @media @desktop {
      & > .content.two-column {
        .left {
          //background-color: @karaoke-t2w-color-lightgray;
        }
        .right > .settings {
          margin: 0 @dim-padding @dim-padding;
          //padding: 0 @dim-padding @dim-padding;
        }
      }
    }
  }
}

.page.search {

  & > .page-block-main {
    width: unset;

    .t2-main-width();
    display: block;
    .t2-text-on-card();
    .t2-card(@level: 2);

  }
}

.page.catalog {
  & > .page-block-main {
    width: unset;

    .t2-main-width();
    display: block;
    .t2-text-on-card();
    .t2-card(@level: 2);
  }
}

.page-playback {
  @media @desktop {
    & {
      .karplayer {
        .t2-background();
        .siri-container {
          background-color: @karaoke-t2w-color-black;
        }
      }
      player-song-copyrights {
        color: @karaoke-t2w-color-lightgray;
        opacity: 0.99; // new context
      }
      .share {
        .t2-background();
      }
      .icon-volume-user, .icon-volume-phone {
        filter: invert(1);
      }
      .sing {
        filter: invert(1);
      }
      .download {
        filter: invert(1);
      }
      .ringtone {
        filter: invert(1);
      }
      .media.control-block {
        .media-body {
          .artist {
            .tele2-font-main-regular();
            .t2-font-header();
            font-weight: bold;
            color: @karaoke-search-placeholder-color;
          }
          .song {
            .tele2-font-main-regular();
            .t2-font-header();
            font-weight: bold;
            color: @karaoke-t2w-color-white;
          }
        }
      }
    }
  }
}


button-row.record-mode {
  & .state-battle .icon {
    background-image: url("./images/ic_mode_record_battle.png");
  }

  & .state-duet .icon {
    background-image: url("./images/ic_mode_record_duet.png");
  }

  & .state-off .icon {
    background-image: url("./images/ic_mode_record_off.png");
  }

  & .state-on .icon {
    background-image: url("./images/ic_mode_record_on.png");
  }
}

player-button {
  .pb-icon {
    &.state-stop {
      background-image: url("../../application/karaoke/player/components/tele2/player_play.png");
    }

    &.state-pause {
      background-image: url("../../application/karaoke/player/components/tele2/player_play.png");
    }

    &.state-play {
      background-image: url("../../application/karaoke/player/components/tele2/player_stop.png");
    }

    &.state-load {
      background-image: url("../../application/karaoke/player/components/tele2/player_wait.png");
      animation: fa-spin 2s infinite linear;
    }
  }

  .pb-label {
    .tele2-font-main-small();
    color: #1F2229;
  }

  .karplayer.desktop & {
    .pb-icon {
      &.state-stop {
        background-image: url("../../application/karaoke/player/components/tele2/player_play_desktop.png");
      }

      &.state-pause {
        background-image: url("../../application/karaoke/player/components/tele2/player_play_desktop.png");
      }

      &.state-play {
        background-image: url("../../application/karaoke/player/components/tele2/player_stop_desktop.png");
      }

      &.state-load {
        background-image: url("../../application/karaoke/player/components/tele2/player_wait.png");
        animation: fa-spin 2s infinite linear;
      }
    }

  }
}


player-button.sing .pb-icon {
  background-image: url("../../application/karaoke/player/components/tele2/player_sing.png");
}

player-button.download .pb-icon {
  background-image: url("../../application/karaoke/player/components/tele2/player_download.png");
}

player-button.ringtone .pb-icon {
  background-image: url("../../application/karaoke/player/components/tele2/player_ringtone.png");
}

player-button.record {
  .pb-icon {
    &.state-disabled {
      background-image: url("../../application/karaoke/player/components/tele2/recorder_disabled.png");
    }

    &.state-off {
      background-image: url("../../application/karaoke/player/components/tele2/recorder_disabled.png");
    }

    &.state-on {
      background-image: url("../../application/karaoke/player/components/tele2/recorder_enabled.png");
    }

    &.state-duet {
      background-image: url("../../application/karaoke/player/components/tele2/recorder_duet_enabled.png");
    }

    &.state-battle {
      background-image: url("../../application/karaoke/player/components/mftj/recorder_battle_enabled.png");
    }
  }
}

body {
  .icon-volume-user {
    background-image: url("./images/ico_volume_user@2x.png");
  }

  .icon-volume-phone {
    background-image: url("./images/ico_volume_phone@2x.png");
  }
}

@media @phone {
  .page-history {
    .app-header-line .text {
      margin-right: 32px; // отступ для иконки
    }
  }
}

.siri-container.desktop, .desktop .siri-container {
  color: @karaoke-t2w-color-white;
}
.siri-container.mobile, .mobile .siri-container {
  color: @karaoke-search-border-color;
}

#main .app-header-line .text {
  line-height: 1.2em;
}


//@import "portal-tele2-black.less";
//@import "app-tele2-black.less";

@import "portal-tele2-white.less";


.t2-footer.desktop .social .adult {
  &:after {
    margin-top: 2px;
    margin-left: 4px;
  }
}

// todo: проверить
p.app-header-line {
  .text, .note {
    & when (@theme = "day") {
      @color: "t2-color-universal-text-black-90-@{theme}";
      color: @@color;
    }

    & when (@theme = "night") {
      @color: "t2-color-universal-text-white-10-@{theme}";
      color: @@color;
    }
  }
}

.karplayer.mobile {
  .t2-card(@level: 1, @theme: 'day', @mediaScope: @phone);
  margin: 0 !important;
  width: auto;
}

/*@media @phone {
  karplayer {
    display: block;
    margin: @dim-padding;
  }
}*/

.page-playback .share {
  .t2-main-width();

  @media @desktop {
    .t2-card(@level: 2, @mediaScope: @desktop);
    .t2-text-on-card();
    .t2-main-width-without-fields();
    margin-top: @dim-padding !important;
  }
}

/// override main.less
.page-block-main.content > :first-child,
.page-block-main > .content > :first-child
  //.page-block > .content > :first-child
{
  /*@media @desktop {
    margin-top: @dim-card-margin-vertical--desktop;
    margin-bottom: @dim-card-margin-vertical--desktop;
  }

  @media @phone {
    margin-top: @dim-card-margin-vertical--mobile;
    margin-bottom: @dim-card-margin-vertical--mobile;
  }*/

  //margin-top: unset;
  //margin-bottom: unset;
}

.page.catalog {
  .catalog-wrapper.catalog-wrapper-details {
    & > .ng-scope {

      @media @desktop {
        .t2-card(2, @mediaScope: @phone, @margin: false);
        margin-bottom: @dim-card-padding--mobile;

        a.album {
          margin-top: @dim-card-padding--mobile;
          margin-bottom: 0;
        }
      }

      @media @phone {
        .t2-card(2, @mediaScope: @phone, @margin: false);
        margin-bottom: @dim-card-padding--mobile;

        a.album {
          margin-top: @dim-card-padding--mobile;
          margin-bottom: 0;
        }

        picture-item .catalog-item {
          box-sizing: border-box;
          width: unset;
          
          .cover {
            width: auto;
            display: block;
          }
        }
      }

    }
  }
}

@import "app-tele2-special.less";
