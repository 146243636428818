body {

  // Полноэкраные модальные окна
  @media (max-width: 768px) {
    .modal-dialog {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;

      .modal-content {
        height: auto;
        min-height: 100%;
        border-radius: 0;
      }
    }
  }
}



body.event-ny {

  #background {
    display: none;
  }

  .bottom-area, .page-block, .page-block-main, body {
    background-color: transparent;
  }

  .page {
    background-image: url("./event/tele2-ny-bg.png");
    background-repeat: repeat;
    background-position: left top;
    @media @retina {
      background-image: url("./event/tele2-ny-bg@2x.png");
    }
  }

  .song-batch-carousel, .disclaimer {
    background-color: #a4a4d424;

    & > .app-label {
      margin: 0 10px 0;
      background-color: transparent;
    }
  }

  .t2-footer.mobile {
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 12px;
      .image-content-full-branding("./event/tele2-ny-snowman.png");
      @media @retina {
        .image-content-branding ("./event/tele2-ny-snowman@2x.png");
      }
    }
  }

  //.page-block .content > promo:first-of-type,
  .page-block .content > promo[header='playlist.event.ny.title']
  {
    & {
      @media @desktop {
        position: relative;
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: -60px;
          right: 164px; // чтобы кнопке подписки не мешался

          .image-content-full-branding("./event/tele2-ny-alf.png");
          @media @retina {
            .image-content-branding ("./event/tele2-ny-alf@2x.png");
          }
        }
      }
    }
    
    .arrow {
      @media @phone {
        position: relative;
        &:after {
          content: '';
          display: block;
          position: absolute;

          top: -36px;
          //top: -46px;
          left: -68px;

          .image-content-full-branding("./event/tele2-ny-alf.png", 0.8);
          @media @retina {
            .image-content-branding ("./event/tele2-ny-alf@2x.png");
          }
        }
      }
    }

  }

  .disclaimer.event-ny {
    display: block;
    padding-bottom: 1em;
    color: #ff3078;
    margin-bottom: -1em;

    a {
      color: #ff3078;
      text-decoration: underline;
    }
  }
}