//@font-fallback: -apple-system, 'BlinkMacSystemFont', system-ui, 'Roboto', 'Segoe UI', 'Helvetica Neue', sans-serif;
@t2-font-fallback: -apple-system, "BlinkMacSystemFont", system-ui, "Roboto", "Segoe UI", "Helvetica Neue", Helvetica, arial, sans-serif;

@t2-font-stencil: 'T2 Halvar Stencil Breit ExBd MidGap', @t2-font-fallback;
@t2-font-heading: 'T2 Halvar Breit', @t2-font-fallback;
@t2-font-regular: 'T2 Rooftop', @t2-font-fallback;

@t2-font-dim-h1-size--desktop: 38px;
@t2-font-dim-h1-line--desktop: 34px;
@t2-font-dim-h2-size--desktop: 28px;
@t2-font-dim-h2-line--desktop: 24px;
@t2-font-dim-h3-size--desktop: 24px;
@t2-font-dim-h3-line--desktop: 22px;
@t2-font-dim-h4-size--desktop: 20px;
@t2-font-dim-h4-line--desktop: 18px;
@t2-font-dim-h5-size--desktop: 16px;
@t2-font-dim-h5-line--desktop: 14px;
@t2-font-dim-h6-size--desktop: 12px;
@t2-font-dim-h6-line--desktop: 10px;

@t2-font-dim-h1-size--mobile: 32px;
@t2-font-dim-h1-line--mobile: 30px;
@t2-font-dim-h2-size--mobile: 24px;
@t2-font-dim-h2-line--mobile: 22px;
@t2-font-dim-h3-size--mobile: 22px;
@t2-font-dim-h3-line--mobile: 20px;
@t2-font-dim-h4-size--mobile: 18px;
@t2-font-dim-h4-line--mobile: 16px;
@t2-font-dim-h5-size--mobile: 16px;
@t2-font-dim-h5-line--mobile: 14px;
@t2-font-dim-h6-size--mobile: 12px;
@t2-font-dim-h6-line--mobile: 10px;

@t2-font-dim-body-desktop-size: 15px;
@t2-font-dim-body-desktop-line: 22px;
@t2-font-dim-tiny-mobile-size: 12px;
@t2-font-dim-tiny-mobile-line: 18px;

@t2-font-dim-button-medium-size: 16px;
@t2-font-dim-button-medium-line: 20px;
@t2-font-dim-button-small-size: 12px;
@t2-font-dim-button-medium-line: 16px;

.font {
  font-family: @t2-font-regular;
}

.font-regular(@size: 15px, @weight: 400) {
  //font-family: 'Roboto', sans-serif;
  //font-family: "Tele2TextSans", "Roboto", @font-fallback;
  .font();
  font-size: @size;
  line-height: 22px;
  font-weight: @weight;
}

.font-regular-bold() {
  .font-regular(@size: 15px, @weight: 600);
}

.font-regular-bolder() {
  .font-regular(@size: 14px, @weight: 700);
}

.font-page-title() {
  .font-regular(@size: 24px, @weight: 600);
  //font-family: 'T2 Halvar Stencil Breit ExBd MidGap', @font-fallback;
  font-family: @t2-font-heading;
}

.font-sub-title() {
  .font-regular(@size: 19px, @weight: 600);
  font-family: @t2-font-heading;
  //font-family: 'T2 Halvar Stencil Breit ExBd MidGap', v;
}

.font-button(@size: 14px, @weight: 400) {
  font-family: @t2-font-heading;
  font-size: @size;
  line-height: 22px;
  font-weight: @weight;
  text-transform: uppercase;
}