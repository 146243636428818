@import "../app-defenitions";
@import "./design-system-t2/app-ds-t2";
@import "./design-system-t2/app-adapter";

//@base: 'white';
@base: 'black';
@theme: 'day';
//@theme: 'night';

//@base-inv: 'white';
//@base-inv: 'black';

/*& when (@base = "black") {
  @base-inv: 'white'
}
& when (@base = "white") {
  @base-inv: 'black'
}*/

@karaoke-t2w-color-white: @t2-color-universal-background-white-level-1-day;
//@karaoke-t2w-color-black: #000000;
//@karaoke-t2w-color-black: #181c25;
@karaoke-t2w-color-black: @t2-color-universal-background-black-level-1-day;
@karaoke-t2w-color-lightgray: #f5f5fa;
@karaoke-t2w-color-gray: #252525;


@karaoke-text-color: @karaoke-t2w-color-black;
@section-text-color: @karaoke-t2w-color-black;
@karaoke-search-text-color: @karaoke-t2w-color-gray;
@karaoke-search-placeholder-color: #bebebe;
@karaoke-search-border-color: #979da3;
@karaoke-search-button-active-color: rgba(16, 165, 222, 0.7);


//@karaoke-primary-accent-color: #3FCBFF;
@karaoke-primary-accent-color: @t2-color-brand-violet-day;
//@karaoke-primary-accent-color: @t2-color-interface-electric-blue-50-day;


.app-icon() {
  width: 32px;
  height: 32px;
  background-size: 32px 32px;
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
  border-radius: 16px;
  z-index: 1;

  .press-glow();
}


.t2-font-logo() {
  font-family: @t2-font-stencil;
}

.t2-font-header(@level: 0, @mediaScope: 'all') {
  font-family: @t2-font-heading;
  font-weight: bold;

  .impl(@level, @type) {
    & when (@level > 0) {
      @size: "t2-font-dim-h@{level}-size--@{type}";
      @height: "t2-font-dim-h@{level}-line--@{type}";
      font-size: @@size;
      line-height: @@height;
    }

    & when (@level > 0) {
      text-transform: uppercase;
    }
  }

  & when (@mediaScope = @desktop) {
    .impl(@level, 'desktop');
  }

  & when (@mediaScope = @phone) {
    .impl(@level, 'mobile');
  }

  & when (@mediaScope = 'all') {
    @media @desktop {
      .impl(@level, 'desktop');
    }

    @media @phone {
      .impl(@level, 'mobile');
    }
  }

}

.t2-font-regular() {
  font-family: @t2-font-regular;
}

@app-dim-button-border-radius: 12px;

.tele2-font-header() {
  .t2-font-header();
  font-weight: bold;
  font-size: 26px;
  //color: #1F2229;
  //line-height: 34px;
  line-height: 28px;
  margin-top: 0;
  margin-bottom: 6px;
}

.tele2-font-main-regular() {
  .t2-font-regular();
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
  //color: #1F2229;
}

.tele2-font-main-small() {
  .t2-font-regular();
  font-style: normal;
  //font-size: 0.8em;
  font-size: 11.2px;
  //color: #8F9399;
  //color: #1F2229;
}

.tele2-font-main-large() {
  .t2-font-regular();
  font-style: normal;
  //font-size: 1.2em;
  font-size: 16px;
  //color: #8F9399;
  //color: #1F2229;
}

.tele2-font-button() {
  .t2-font-regular();
  //font-size: 1.2em;
  font-size: 16px;
  //color: #8F9399;
  //color: #1F2229;
  line-height: 18px;
}

/*
.image-content-branding-m (@image-name) {
  @image-path: "./tele2/" + @image-name;
  background-image: url(@image-path);
  //background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
*/

// from app-*.less -----------------------------------------------------------------------------------------------------


// Голубой – rgb(40, 195, 245);
// Розовый – rgb(210, 75, 145);
// Бирюзовый – rgb(90, 195, 180);
// Красный – rgb(235, 100, 70);
// Оранжевый – rgb(245, 150, 30);
// Зеленый – rgb(165, 205, 60);

@section-main-bg-color: #3FCBFF;
@section-catalog-bg-color: #3FCBFF;
@section-popular-bg-color: #38FFA6;
@section-playlist-bg-color: #C882FF;
@section-new-bg-color: #FF59A3;
@section-feedback-bg-color: #C882FF;

@section-main-border-color: @section-catalog-bg-color;
@section-catalog-border-color: @section-catalog-bg-color;
@section-popular-border-color: @section-popular-bg-color;
@section-playlist-border-color: @section-playlist-bg-color;
@section-new-border-color: @section-new-bg-color;
@section-feedback-border-color: @section-playlist-bg-color;

@diff: 5%;

@section-main-hover-bg-color: darken(@section-main-bg-color, @diff);
@section-main-hover-border-color: @section-main-hover-bg-color;

@section-catalog-hover-bg-color: darken(@section-catalog-bg-color, @diff);
@section-catalog-hover-border-color: @section-catalog-hover-bg-color;

@section-popular-hover-bg-color: darken(@section-popular-bg-color, @diff);
@section-popular-hover-border-color: @section-popular-hover-bg-color;

@section-playlist-hover-bg-color: darken(@section-playlist-bg-color, @diff);
@section-playlist-hover-border-color: @section-playlist-hover-bg-color;

@section-new-hover-bg-color: darken(@section-new-bg-color, @diff);
@section-new-hover-border-color: @section-new-hover-bg-color;

@section-feedback-hover-bg-color: darken(@section-feedback-bg-color, @diff);
@section-feedback-hover-border-color: @section-playlist-hover-bg-color;


.t2-card(@level: 1, @theme: @theme, @mediaScope: 'any', @margin: true, @padding: true) {
  //padding: @dim-padding;
  //border-radius: @dim-card-outer-radius;
  overflow: hidden;

  //margin: @dim-padding 0;

  //background-color: @app-color-page-card-background;
  & when(@level >= 1) {
    @back-color: "t2-color-interface-background-level-@{level}-@{theme}";
    background-color: @@back-color;
  }

  box-sizing: border-box;
  //padding: @app-dim-page-chat-wrapper-padding-regular;

  .mediaDesktop() {
    & when(@level = 1) {
      border-radius: @dim-card-border-radius--desktop;
    }
    & when(@level > 1) {
      border-radius: @dim-card-border-radius-internal--desktop;
    }
    & when (@padding = true) {
      padding: @dim-card-padding--desktop;
    }
    & when (@margin = true) {
      margin: @dim-card-margin-vertical--desktop auto;
    }
  }

  .mediaPhone() {
    & when(@level = 1) {
      border-radius: @dim-card-border-radius--mobile;
    }
    & when(@level > 1) {
      border-radius: @dim-card-border-radius-internal--mobile;
    }
    & when (@padding = true) {
      padding: @dim-card-padding--mobile;
    }
    & when (@margin = true) {
      margin: @dim-card-margin-vertical--mobile @dim-layout-margin--mobile;
    }
  }

  & when (@mediaScope = 'any') {
    @media @desktop {
      .mediaDesktop();
    }

    @media @phone {
      .mediaPhone()
    }
  }

  & when (@mediaScope = @desktop) {
    .mediaDesktop();
  }

  & when (@mediaScope = @phone) {
    .mediaPhone()
  }

  & when(@level <= 2) {
    box-shadow: @app-color-page-card-shadow;
  }
}

.t2-main-width-with-fields() {
  margin: 0 auto;
}

.t2-main-width-without-fields() {
  @mw: (@page-content-max-width - (@desktop-offset * 2) );
  max-width: @mw;
  max-width: min(@mw, calc(100% - (2 * @desktop-offset)));
}

.t2-main-width() {
  //display: block;
  @media @desktop {
    //margin: 0 auto;
    //margin: @dim-card-margin-vertical--desktop auto;
    //.t2-main-width-with-fields();

    //margin-top: @dim-card-margin-vertical--desktop;
    margin: @dim-card-margin-vertical--desktop auto;

    //.t2-main-width-without-fields();
  }

  @media @phone {
    margin: @dim-card-margin-horizontal--mobile @dim-card-margin-vertical--mobile;
  }
}

.t2-text-on-card(@clickable: false, @base: @base, @theme: @theme) {
  & when (@theme = "day") {
    @color: "t2-color-universal-text-black-90-@{theme}";
    @color-hover: "t2-color-universal-text-black-60-@{theme}";

    color: @@color;

    & when (@clickable = true) {
      &:hover {
        color: @@color-hover;
      }
    }
  }

  & when (@theme = "night") {
    @color: "t2-color-universal-text-white-10-@{theme}";
    @color-hover: "t2-color-universal-text-white-30-@{theme}";

    color: @@color;

    & when (@clickable = true) {
      &:hover {
        color: @@color-hover;
      }
    }
  }
}

.t2-text-on-back(@base: @base, @theme: @theme) {
  & when (@base = "white") {
    @color: "t2-color-universal-text-black-90-@{theme}";
    color: @@color;
  }

  & when (@base = "black") {
    @color: "t2-color-universal-text-white-10-@{theme}";
    color: @@color;
  }
}

.t2-background() {
  @background-color: "t2-color-universal-background-@{base}-level-1-@{theme}";
  background-color: @@background-color;
}
