@font-face {
    font-family: 'T2 Halvar Stencil Breit ExBd MidGap';
    src: url('./T2HalvarStencilBreit-ExtraBoldMidGap.eot');
    src: url('./T2HalvarStencilBreit-ExtraBoldMidGap.eot?#iefix') format('embedded-opentype'),
        url('./T2HalvarStencilBreit-ExtraBoldMidGap.woff2') format('woff2'),
        url('./T2HalvarStencilBreit-ExtraBoldMidGap.woff') format('woff'),
        url('./T2HalvarStencilBreit-ExtraBoldMidGap.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'T2 Halvar Breit';
    src: url('./T2HalvarBreit-ExtraBold.eot');
    src: url('./T2HalvarBreit-ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('./T2HalvarBreit-ExtraBold.woff2') format('woff2'),
        url('./T2HalvarBreit-ExtraBold.woff') format('woff'),
        url('./T2HalvarBreit-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
  font-family: 'T2 Rooftop';
  src: url('./T2Rooftop-Regular.eot');
  src: url('./T2Rooftop-Regular.eot?#iefix') format('embedded-opentype'),
  url('./T2Rooftop-Regular.woff2') format('woff2'),
  url('./T2Rooftop-Regular.woff') format('woff'),
  url('./T2Rooftop-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'T2 Rooftop';
  src: url('./T2Rooftop-Medium.eot');
  src: url('./T2Rooftop-Medium.eot?#iefix') format('embedded-opentype'),
  url('./T2Rooftop-Medium.woff2') format('woff2'),
  url('./T2Rooftop-Medium.woff') format('woff'),
  url('./T2Rooftop-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
