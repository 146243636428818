@import "../../branding/app-defenitions";

//@import "font";
@import "mixins";
//@import "element-menu";
@import "element-loading";
@import "element-header";
@import "element-header-line";
@import "element-promo-triple";
@import "element-promo-batchsong-carousel";
@import "element-song-item";
@import "element-cover";
//@import "element-catalog";
@import "main";
@import "karaoke";


@media @desktop {
  .mobile {
    display: none !important;
  }
}
@media @phone {
  .desktop {
    display: none !important;
  }
}
@import "pages";
